import React, { useEffect, useRef, useState } from 'react';
import { useInterval, useOnClickOutside } from 'usehooks-ts';
import { add, format, parse, parseISO, startOfMonth, sub, setDate, getDaysInMonth, subMonths, addMonths } from 'date-fns';
import { toast } from 'react-toastify';
import i18n from 'i18next';

import { ExportJobStatus, createExportJob, getExport } from 'api/routes/export';
import { userWithReportList } from 'api/routes/user';
import ModalHeader from 'components/Modal/ModalHeader';
import { ModalContainer } from 'components/Modal/style';
import { useExportServices } from 'providers/ExportServicesProvider';
import downloadFile from 'utils/downloadFile';
import { showError } from 'utils/error';
import { useClient } from 'providers/client';
import { Flex, Margin } from 'styles';
import Checkbox, { State } from 'components/Checkbox';
import { usePreferences } from 'providers/preferences';
import { BoldSpan } from 'widgets/Report/components/Modal/style';

import { useModal } from '../Modal';
import {
	CircularProgress,
	DateInput,
	DateSelection,
	ModalBody,
	ReportSelector,
	Separator,
	UserListContainer,
	ValidateButton,
} from './styles';
import { useLocalStorageState } from 'utils/common';
import { useKeycloak } from 'providers/keycloak';

export type ParsedDateFromSettings = {
	exportStartPeriod: number;
	exportStartDay: number;
	exportEndPeriod: number;
	exportEndDay: number;
}

export const dateBaseOnSettings = (worksiteSettings: string, currentDate: Date): { startDate: Date, endDate: Date } | undefined => {
	try {
		const exportDate = JSON.parse(worksiteSettings) as Partial<ParsedDateFromSettings>;
		if (exportDate.exportEndDay === undefined || exportDate.exportEndPeriod === undefined || exportDate.exportStartDay === undefined || exportDate.exportStartPeriod === undefined) {
			return undefined;
		}

		const startDate = exportDate.exportStartPeriod === -1 ? subMonths(currentDate, 1) : exportDate.exportStartPeriod === 1 ? addMonths(currentDate, 1) : currentDate;
		const endDate = exportDate.exportEndPeriod === -1 ? subMonths(currentDate, 1) : exportDate.exportEndPeriod === 1 ? addMonths(currentDate, 1) : currentDate;
		const maxDays = getDaysInMonth(endDate);

		return {
			startDate: setDate(startDate, exportDate.exportStartDay),
			endDate: setDate(endDate, Math.min(exportDate.exportEndDay, maxDays))
		};
	} catch (error) {
		console.error(error)
		return undefined;
	}
}

export const ExportModal: React.FC = () => {
	const { keycloak } = useKeycloak();
	const { clientHandler: { client, exportClient }, worksite } = useClient();
	const { pop } = useModal();
	const modalRef = useRef() as React.MutableRefObject<HTMLInputElement>;

	const [currentlyViewedDate] = useLocalStorageState<Date>('homepage-calendar', new Date(), keycloak, (value) => parseISO(value));

	const { services } = useExportServices();
	const { preferences } = usePreferences();

	const datesFromWorksite = dateBaseOnSettings(worksite.settings, currentlyViewedDate);
	const [service, setService] = useState<string>('');

	const [users, setUsers] = useState<UserResponse[]>([]);
	const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
	const [selectAll, setSelectAll] = useState<boolean>(true);

	// Select the first available service when they become available
	useEffect(() => {
		setService(services?.at(0)?.name ?? '');
	}, [services]);

	const [startDate, setStartDate] = useState<Date>(
		datesFromWorksite?.startDate ??
		sub(add(startOfMonth(currentlyViewedDate), { days: 15 }), { months: 1 }),
	);
	const [endDate, setEndDate] = useState<Date>(
		datesFromWorksite?.endDate ??
		add(startOfMonth(currentlyViewedDate), { days: 14 })
	);

	const [jobId, setJobId] = useState<string>('');

	const handleCloseModal = (): void => {
		if (jobId !== '') return;

		pop();
	};

	useEffect(() => {
		const fetchUserList = async (): Promise<void> => {
			const lists = await userWithReportList(client, new Date(format(startDate, 'yyyy-MM-dd')), new Date(format(endDate, 'yyyy-MM-dd')));
			const filteredUsers = lists.sort((a, b) => (a.displayName ?? a.userName).localeCompare(b.displayName ?? b.userName));

			setUsers(filteredUsers);
			setSelectedUsers(filteredUsers.map((e) => e.userName));
		};
		fetchUserList().catch(showError('Unable to fetch export services'));
	}, [startDate, endDate]);

	useOnClickOutside(modalRef, handleCloseModal);

	useInterval(
		() => {
			(async () => {
				const [status, fileContent] = await getExport(exportClient, service, jobId);

				if (status === ExportJobStatus.UNKNOWN_ID_OR_ALREADY_GOT) {
					setJobId('');
					return;
				}

				if (status === ExportJobStatus.DONE) {
					if (fileContent == null) return;

					const serviceType = services.find((s) => s.name === service)?.type ?? '';
					downloadFile(
						`export_${service}__${format(startDate, 'yyyy-MM-dd')}_${format(endDate, 'yyyy-MM-dd')}.${serviceType}`,
						fileContent,
						serviceType,
					);

					setJobId('');

					toast.dismiss();
					toast.info(i18n.t(`reports_exported`));
					pop();
				}
			})().catch(console.error);
		},
		jobId === '' ? null : 2_000,
	);

	return (
		<ModalContainer ref={modalRef} customHeight='auto' maxWidth="60rem">
			<ModalHeader title={i18n.t('export_daily_reports')} pop={handleCloseModal} />
			<ModalBody onSubmit={(e) => e.preventDefault()}>
				<h3 style={{ margin: 0 }}>{i18n.t('select_report')}</h3>
				<ReportSelector
					name="export-report"
					id="export-report-select"
					value={service}
					onChange={(e) => setService(e.target.value)}
				>
					{services?.length === 0 && <option value="">{i18n.t('loading_reports')}</option>}

					{services?.map((s) => (
						<option key={s.name} value={s.name}>
							{s.description}
						</option>
					))}
				</ReportSelector>

				<h3 style={{ margin: 0 }}>{i18n.t('period_choice')}</h3>
				<DateSelection>
					<span className="fs-14">{i18n.t('from')}</span>
					<DateInput
						type="date"
						value={format(startDate, 'yyyy-MM-dd')}
						onChange={(e) => {
							if (e.target.value === '') {
								setStartDate(new Date());
								return;
							}
							setStartDate(parse(e.target.value, 'yyyy-MM-dd', new Date()))
						}}
					/>
					<span className="fs-14">{i18n.t('to')}</span>
					<DateInput
						type="date"
						value={format(endDate, 'yyyy-MM-dd')}
						onChange={(e) => {
							if (e.target.value === '') {
								setEndDate(new Date());
								return;
							}
							setEndDate(parse(e.target.value, 'yyyy-MM-dd', new Date()))
						}}
					/>
				</DateSelection>

				<h3 style={{ margin: 0 }}>{i18n.t('select_scope')}</h3>
				<UserListContainer>
					<Flex className="report-modal" flexDirection="row">
						<Checkbox state={users.length === selectedUsers.length ? State.Checked : State.Unchecked} onClick={() => {
							const newSelectState = !selectAll;

							setSelectedUsers(newSelectState ? users.map((e) => e.userName) : []);
							setSelectAll(newSelectState);
						}} />
						<Margin left={16} centerContent>
							<BoldSpan>{i18n.t('all_scope')}</BoldSpan>
						</Margin>
					</Flex>
					<Separator />
					{users
						.map((u, idx) => (
							<Flex key={`${u.userName}-${idx}`} className="report-modal" flexDirection="row">
								<Checkbox state={selectedUsers.includes(u.userName) ? State.Checked : State.Unchecked} onClick={() => {
									setSelectedUsers(
										selectedUsers.includes(u.userName)
											? selectedUsers.filter((e) => u.userName !== e)
											: [...selectedUsers, u.userName]
									);
								}} />
								<Margin left={16} centerContent>
									<BoldSpan>{u.displayName === '' ? u.userName : u.displayName}</BoldSpan>
								</Margin>
							</Flex>
						))}
				</UserListContainer>

				<ValidateButton
					type="submit"
					data-loading={jobId !== ''}
					disabled={service === '' || jobId !== ''}
					className="fs-20"
					onClick={() => {
						createExportJob(exportClient, service, { startDate, endDate, users: selectedUsers, WorksiteId: worksite._id, locale: preferences.language ?? 'en' }).then((j) => {
							setJobId(j);

							toast.info(i18n.t(`export_job_started`), {
								isLoading: true,
								closeButton: false,
								autoClose: false,
								closeOnClick: false,
							});
						}).catch(showError);
					}}
				>
					{jobId === '' && i18n.t('validate')}
					{jobId !== '' && (
						<CircularProgress>
							<progress value="75" max="100">
								75%
							</progress>
						</CircularProgress>
					)}
				</ValidateButton>
			</ModalBody>
		</ModalContainer>
	);
};
