import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useOnClickOutside } from "usehooks-ts";

import { RolesByWorksite, useKeycloak } from "providers/keycloak";
import Button, { Type as ButtonType } from "components/Button";
import { ModalContainer } from 'components/Modal/style';
import ModalHeader from "components/Modal/ModalHeader";
import { ModalBody, ReportSelector } from "components/ExportModal/styles";
import { useClient } from "providers/client";
import { useModal } from "components/Modal";
import routes from "utils/routes";
import i18n from "utils/lang";

import { ButtonContainer } from "./style";

type Props = {
    isFullPage?: boolean;
}

const SelectWorksite: React.FC<Props> = ({ isFullPage = false }) => {
    const { pop } = useModal();
    const navigate = useNavigate();
    const { worksite } = useClient();
    const { roleByWorksite } = useKeycloak();
    const modalRef = useRef() as React.MutableRefObject<HTMLDivElement>;
    const filteredRoleByWorksite = roleByWorksite
        .filter((e): e is Required<RolesByWorksite> => !!e.unauthData);
    const [code, setCode] = useState<string | undefined>(
        filteredRoleByWorksite.find((e) => e.unauthData.code === worksite.code)?.unauthData.code
    );

    const goToWorksite = (): void => {
        if (code) {
            navigate(`${routes.worksite}/${code}`);
        }
    }

    useOnClickOutside(modalRef, pop);

    return (
        <ModalContainer ref={modalRef} customHeight="auto" maxWidth="40rem">
            {
                isFullPage
                    ? <ModalHeader title={i18n.t('select_worksite')} pop={pop} showPop={false} />
                    : <ModalHeader title={i18n.t('change_site')} pop={pop} />
            }
            
            <ModalBody onSubmit={(e) => e.preventDefault()}>
                <ReportSelector
                    name="export-report"
                    id="export-report-select"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                >
                    {filteredRoleByWorksite
                        .sort((a, b) => (a.unauthData.name ?? "").localeCompare(b.unauthData.name ?? ""))
                        .map((e) => (
                            <option key={`worksite-${e.worksite}`} value={e.unauthData.code}>
                                {e.unauthData.name ?? e.unauthData.code}
                            </option>
                        ))}
                </ReportSelector>
            </ModalBody>
            <ButtonContainer>
                <Button type={ButtonType.modalValidator} onClick={goToWorksite} disabled={code === undefined || code === ""}>
                    {i18n.t('validate')}
                </Button>
            </ButtonContainer>
        </ModalContainer>
    );
}


export default SelectWorksite;