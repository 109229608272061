import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import * as R from 'ramda';

import { getRefByTask } from 'api/routes/referential';
import { fetchSource } from 'api/routes/source';
import { CategoryType } from 'api/types/category';
import { usePreferences } from 'providers/preferences';
import { counterBySource } from 'api/routes/counter';
import { FeaturesFlag, hasFlags } from 'api/utils';
import { useClient } from 'providers/client';
import { SelectableReferential, SelectableSource, WithSelection, WithTitle } from './type';

export type DataResult = {
	fav: boolean;
	setFav: React.Dispatch<React.SetStateAction<boolean>>;
	refs: SelectableReferential[];
	setRefs: React.Dispatch<React.SetStateAction<SelectableReferential[]>>;
	sources: SelectableSource[];
	setSources: React.Dispatch<React.SetStateAction<SelectableSource[]>>;
};

type EotpParams = {
	date: string;
	currentCategory: CategoryType;
};

const useData = (favDefaultValue: boolean, category?: CategoryType, eotp?: EotpParams): DataResult => {
	const { clientHandler: { client, referentialClient } } = useClient();
	const { preferences } = usePreferences();
	const [fav, setFav] = useState<boolean>(favDefaultValue);
	const [refs, setRefs] = useState<SelectableReferential[]>([]);
	const [sources, setSources] = useState<SelectableSource[]>([]);
	const hasLabelBefore = category && hasFlags(category.features, FeaturesFlag.LabelBefore);

	const refsRef = useRef<Map<boolean, (SelectableReferential & WithSelection & WithTitle)[]>>(new Map());

	useEffect(() => {
		if (category) {
			void (async () => {
				if (!category.refID) return;

				// We can rely on the "cache" of the modal to avoid sending any more calls to the api when the value is known
				const cachedRefs = refsRef.current.get(fav);
				if (cachedRefs) {
					setRefs(cachedRefs);
					return;
				}

				const sortValues = category.refID === '2' ?
					R.sortWith<SelectableReferential & WithSelection & WithTitle>([
						// @ts-expect-error Works perfectly but tslint somehow detects an error
						R.ascend(R.prop('title')),
					]) :
					R.sortWith<SelectableReferential & WithSelection & WithTitle>([
						// @ts-expect-error Works perfectly but tslint somehow detects an error
						R.descend(R.prop('isSelected')),
						// @ts-expect-error Works perfectly but tslint somehow detects an error
						R.ascend(R.prop('title')),
					]);
				try {
					const refTaskIDs = preferences.tasks?.find((e) => e.categoryID === category._id)?.refs.map((p) => p.id) ?? [];
					const rValue = await getRefByTask(referentialClient, category.refID);
					if (rValue) {
						const parsedValue = fav
							? rValue.filter((r) => refTaskIDs.some((p) => r.id === p)).map((r) => ({ ...r, isSelected: false }))
							: rValue.map((r) => ({
								...r,
								isSelected: false,
							}));
						const sortedRefs = sortValues(
							parsedValue.map((e) => ({
								...e,
								title: hasLabelBefore
									? [e.label, e.id].filter(Boolean).join(' - ')
									: [e.id, e.label].filter(Boolean).join(' - '),
							})),
						);
						refsRef.current.set(fav, sortedRefs);
						setRefs(sortedRefs);
					}

					const codes = preferences.tasks?.find((t) => t.categoryID === (!!eotp ? eotp.currentCategory._id : category._id))?.refs.map((r) => r.id) ?? [];
					const sValue = !!eotp ? await counterBySource(client, new Date(eotp.date), {prefCodes: codes}) : await fetchSource(client);
					if (sValue) {
						const catID = !!eotp ? eotp.currentCategory._id : category._id;
						// CHECK IF THERE IS A NEED TO UPDATE THE SOURCES
						if (!_.isEqual(
							sValue.filter((e) => !!catID && e.CategoryId === catID).map(e => e._id),
							sources.map(e => e._id))) {
							setSources(sValue.filter((e) => !!catID && e.CategoryId === catID).sort((a, b) => a.order - b.order));
						}
					}
				} catch (err) {
					console.error(err);
				}
			})();
		}
	}, [category, fav]);

	// With this effect we ensure remembering any checked value even when the favorites toggled is used back and forth
	useEffect(() => {
		refsRef.current.set(fav, refs);
	}, [fav, refs]);

	return {
		fav,
		setFav,
		refs,
		setRefs,
		sources,
		setSources,
	};
};

export default useData;
