import * as dateFns from 'date-fns';
import React, { useCallback, useContext, useId, useMemo, useState } from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';

import { createCheckedCounterForReport, updateCheckedCounter } from 'api/routes/checkedCounter';
import { createCounter, updateCounter } from 'api/routes/counter';
import { CounterType } from 'api/types/counter';
import { ReportStatus } from 'api/types/report';
import { SourceType } from 'api/types/source';
import { TaskType } from 'api/types/task';
import Calendar from 'assets/svg/Calendar';
import Verified from 'assets/svg/Verified';
import Button, { Type as ButtonType } from 'components/Button';
import Checkbox, { State } from 'components/Checkbox';
import Input, { Style } from 'components/Input';
import CustomTooltip from 'components/Tooltip';
import { useClient } from 'providers/client';
import { useReports } from 'providers/ReportsProvider';
import { usePreferences } from 'providers/preferences';
import { Flex } from 'styles';
import { showError } from 'utils/error';
import i18n, { getLocaleFromCode } from 'utils/lang';
import { ReportDataSourceContext } from 'widgets/Report/provider/counters/sources';

import { Col, ColNotSpecified, ColType, Cols, ScrollCols, TotalCol } from 'widgets/Report/style';
import { getFilterColor } from '../../provider/counters/filter';
import {
	AccordionRowText,
	InputContainer,
	InputSize,
	RowContainer,
	RowInput,
	ViewType,
} from './style';
import CounterByDropdown from '../Dropdown';

type ContentProps = {
	task: TaskType;
	unit: string;
	counter: CounterType;
	sourceID: string;
	inputString: string;
	inputId: string;
};

// Building ASTs for regex is a costly operation.
// Regex are defined here to only do that operation once.
const nonNumberCharsRegex = /[^0-9.]/g;
const extraEndingDotsRegex = /\.\.+$/;
const validNumberRegex = /^\d+(\.\d*)?$/;


export const Content: React.FC<ContentProps> = ({ task, unit, counter, sourceID, inputString, inputId }) => {
	const { clientHandler: { client } } = useClient();
	const { fetchCounters } = useContext(ReportDataSourceContext);
	const [inputValue, setInputValue] = useState<string>(inputString);
	const { preferences } = usePreferences();
	const { canEdit, reports, currentReport } = useReports();

	const updateCounterFn =
		canEdit && currentReport?.status === ReportStatus.Validated ? updateCheckedCounter : updateCounter;
	const createCounterFn =
		canEdit && currentReport?.status === ReportStatus.Validated
			? createCheckedCounterForReport(currentReport?._id ?? -1)
			: createCounter;

	const verifiedId = useId();

	const updateOrCreateQuantity = useCallback(async (): Promise<CounterType | void> => {
		if (!validNumberRegex.test(inputValue)) return;

		const value = parseFloat(inputValue);

		if (task._id) {
			return updateCounterFn(client, { quantity: value }, task._id.toString()).catch(showError('Failed to update counter'));
		} else {
			return createCounterFn(client, {
				task: task.task,
				quantity: value,
				SourceId: sourceID,
				comment: counter.comment ?? '',
				cost: counter.cost ?? 0,
				tags: counter.tags ?? [],
				...counter,
			}).catch(showError('Failed to create counter'));
		}
	}, [inputValue, task, sourceID, counter, updateCounter, createCounter]);

	const handleOnBlur = useCallback(() => {
		void (async () => {
			try {
				// Ensure no extra dot stays in the input field if the input value ends with a dot
				if (inputValue.endsWith('.')) {
					setInputValue(inputValue.slice(0, -1));
				}

				await updateOrCreateQuantity();
				fetchCounters();
			} catch (err) {
				showError(`Failed to create or update quantity`)(err as Error);
			}
		})();
	}, [inputValue, updateOrCreateQuantity, fetchCounters, updateCounterFn, createCounterFn]);

	const handleOnChange = useCallback(
		(evt: React.ChangeEvent<HTMLInputElement>) => {
			if (!canEdit) return;
			let cleanValue = evt.target.value.replace(nonNumberCharsRegex, '');
			if (cleanValue === '.') cleanValue = '0';
			cleanValue = cleanValue.replace(extraEndingDotsRegex, '.');

			if (validNumberRegex.test(cleanValue)) {
				setInputValue(cleanValue);
			}
			if (cleanValue.length === 0) {
				setInputValue('0');
			}
		},
		[canEdit, setInputValue],
	);

	const validatedInReport = useMemo(() => {
		return !task.ValidatedIn ? null : reports.find((r) => r._id === task.ValidatedIn) ?? null;
	}, [task.ValidatedIn, reports]);

	return (
		<InputContainer>
			{validatedInReport != null && (
				<>
					<div className="verified-icon-container" data-tooltip-id={verifiedId}>
						<Verified />
					</div>
					<CustomTooltip
						id={verifiedId}
						place="bottom-start"
						positionStrategy="fixed"
					>
						<span>
							{i18n.t('validated_by')}: {validatedInReport.editor}
						</span>
						<Flex alignItems="center" gap=".5em">
							<Calendar /> {i18n.t('onDay').at(0)?.toUpperCase() ?? ''}
							{i18n.t('onDay').slice(1)}{' '}
							{dateFns.format(new Date(validatedInReport.dateReport), 'EEEE dd', { locale: getLocaleFromCode(preferences.language) })}
						</Flex>
					</CustomTooltip>
				</>
			)}

			<RowInput size={task.task === "" ? InputSize.ReportUndefined : InputSize.ReportContent}>
				<Input
					value={inputValue !== "0" ? inputValue : undefined}
					placeholder={inputValue === "0" ? "0" : undefined}
					disabled={validatedInReport !== null}
					onChange={handleOnChange}
					onBlur={handleOnBlur}
					id={inputId}
					onFocus={(evt) => evt.target.select()}
					textAlign="end"
					style={Style.Content}
				/>
				{task.task === '' && (
					<div>{unit}</div>
				)}
			</RowInput>
		</InputContainer>
	);
};

type RowProps = {
	counter: CounterType;
	sourceID: string;
	sourcesWithSameUnit: SourceType[];
	unit: string;
	showEOTPs: boolean;
};

const Row: React.FC<RowProps> = ({ counter, sourceID, unit, showEOTPs, sourcesWithSameUnit }) => {
	const { clientHandler: { client } } = useClient();
	const filterColor = getFilterColor(counter.comeFrom);
	const unknownData: TaskType | undefined = counter.tasks.find((e) => e.task === '');
	const datas: TaskType[] = counter.tasks.filter((e) => e.task !== '');
	const { fetchCounters, sources } = useContext(ReportDataSourceContext);
	const { canEdit, currentReport } = useReports();
	const isChecked = counter.include;
	const counterTitleID = useId();

	const currentSource = useMemo(() => sources.find((s) => s._id.toString() === sourceID), [sources, sourceID]);

	const baseId = useId();

	const updateCounterFn =
		canEdit && currentReport?.status === ReportStatus.Validated ? updateCheckedCounter : updateCounter;
	const updateCounters = (): void => {
		const taskIds = counter.tasks.filter(({ _id }) => _id != null).map(({ _id }) => _id);

		Promise.all(
			taskIds
				.filter((taskID): taskID is number => taskID !== undefined)
				.map((taskID) => updateCounterFn(client, { include: !isChecked }, taskID.toString())),
		)
			.then(() => setTimeout(() => {
				fetchCounters();
			}, 400))
			.catch(showError('Failed to update counters'));
	};

	return (
		<RowContainer type={ViewType.CounterBySource}>
			<Flex
				alignItems="center"
				width="95%"
				backgroundColor="var(--title-column-bg-color, unset)"
				borderRadius="8px"
				minWidth="0"
				margin="0 0 0 10px"
			>
				<Checkbox disabled={!canEdit} onClick={updateCounters} state={isChecked ? State.Checked : State.Unchecked} />
				<Button type={ButtonType.colorized} color={filterColor} />
				<AccordionRowText>
					<span data-tooltip-id={counterTitleID}>{counter?.title}</span>
					<CustomTooltip
						id={counterTitleID}
						place='top-start'
					>
						{counter?.title}
					</CustomTooltip>
				</AccordionRowText>
			</Flex>
			<Flex style={{ position: "relative" }}>
				<CounterByDropdown
					canDelete={!!counter && !!!counter.comeFrom}
					subtitle={`${currentSource?.label ?? ''} (${currentSource?.unit ?? 'U'}) | ${counter.code}`}
					sources={sourcesWithSameUnit}
					tasks={counter.tasks}
					counter={counter}
				/>
			</Flex>
			<ColNotSpecified type={ColType.Row}>
				{!!unknownData && (
					<Content
						task={unknownData}
						unit={unit}
						counter={counter}
						sourceID={sourceID}
						inputString={unknownData.quantity.toString()}
						inputId={`${baseId}-${unknownData?._id ?? ''}-${unknownData?.task}`}
					/>
				)}
			</ColNotSpecified>

			<Cols>
				{showEOTPs && datas.length > 0 && (
					<ScrollSyncPane group="horizontal">
						<ScrollCols type={ColType.Row}>
							{datas.map((value, idx) => (
								<Col key={`Row-${counter.code}-${idx}-${value.task}-${value._id || ''}`} type={ColType.Row}>
									<Content
										task={value}
										unit={unit}
										counter={counter}
										sourceID={sourceID}
										inputString={value.quantity.toString()}
										inputId={`${baseId}-${value?._id ?? ''}-${value?.task}`}
									/>
								</Col>
							))}
						</ScrollCols>
					</ScrollSyncPane>
				)}
			</Cols>
			<TotalCol type={ColType.Row}>
				{Intl.NumberFormat(navigator.language, { maximumFractionDigits: 2 }).format(counter.sum ?? 0)} {unit}
			</TotalCol>
		</RowContainer>
	);
};

export default Row;
